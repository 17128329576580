import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '68, 20, 3',
		'primary-dark': '46, 12, 0',
		'accent': '227, 30, 36',
		'accent-plus': '255, 255, 255',
	},
});
